"use client";
import { useState } from "react";
import style from "./style.module.scss";
import ArrowRight from "@/assets/icons/ArrowRightBlack.svg";
import SafeLink from "@/components/SafeLink";
import { useRouter } from "next/navigation";
import { JDIProductCardV2 } from "@/lib/jdiClientUtils";
import SaleTips from "./saleTips";
import { useEffect } from "react";

interface HappyHourProps {
  moduleData: any;
  currencySymbol: string;
}
export default function Module({ moduleData, currencySymbol }: HappyHourProps) {
  const [pageConfig, setPageConfig] = useState<any>({});
  const router = useRouter();

  const { dataBatch } = moduleData;
  const initConfig = (moduleData: any) => {
    const newPageConfig = {
      moreLinks: "/happy",
      products: moduleData?.externalData?.data || {},
      title: moduleData?.dataBatch?.title[0] || "",
    };

    newPageConfig.products?.forEach((p: any) => {
      p.type = "1";
      p.currency = currencySymbol;
      p.onClickProduct = () => {
        router.push(`/happy?groupId=${p.groupId}&batchId=${p.batchId}`);
      };
      p.saleTipsDom = SaleTips(p.stockLabelText);

      p.width = "220px";
      p.height = "302px";
      p.skuNameStyle = {
        fontSize: "16px",
        color: "#1a1a1a",
      };
      p.containerStyle = {
        flex: "0 0 auto",
      };
      p.skuImgStyle = {
        width: "200px",
        height: "200px",
      };
      p.saleTips = {
        discount: p.extInfo?.labels?.labelData?.["1"]?.value,
        saveX: p.extInfo?.labels?.labelData?.["11"]?.value,
        onlyXLeft: p?.stock < 10 ? p.stock : null,
      };
      p.realPriceJDIPriceParams = {
        integerStyle: {
          fontSize: "24px",
        },
      };
    });
    setPageConfig(newPageConfig);
  };

  useEffect(() => {
    initConfig(moduleData);
  }, [moduleData, currencySymbol]);

  if (!pageConfig.products?.length) return null;

  return (
    <div className={style["uk-pc-happy-hour"]}>
      <SafeLink href={pageConfig.moreLinks}>
        <div className={style.bar}>
          <p className={style.title}>{pageConfig?.title?.title}</p>
          <div className={style.subTitle}>
            <p>{pageConfig?.title?.subTitle}</p>
            <ArrowRight />
          </div>
        </div>
      </SafeLink>

      <div className={style.products}>
        {pageConfig.products?.map((product: any) => (
          <JDIProductCardV2 productData={product} key={product.skuId} />
        ))}
      </div>
    </div>
  );
}
