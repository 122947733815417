"use client";
import style from "./style.module.scss";
// import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { queryProductFeeds } from "./src/api/index";
import { seckillBatchDtoT } from "./src/types/index";
import TabBar from "./src/components/tabBar";
import ProductList from "./src/components/productList";
import ChevronDown from "./src/components/chevronDown";
import UKPageNothing from "@/common-components-src/components/UKPageNothing";
import { sendPv } from '@/tracks/25208'
import dayjs from 'dayjs'
import ReservationBtns from './src/components/reservationBtns'

export default function Module({ moduleData, currentMills }: { moduleData: any, currentMills: number }) {
  const pageConfig: any = {};

  const [tabBarData, setTabBarData] = useState<any>(null); // tabBar数据
  const [activeIndex, setActiveIndex] = useState<any>(null); // 当前点击的tabBar下标
  const [productList, setProductList] = useState<any>(null); // 页面展示数据
  const [loadError, setLoadError] = useState<any>(); // 控制显示暂无数据效果
  const [loadingState, setLoadingState] = useState<string>("loaded"); // 控制底部显示加载状态 loaded  loading  noMore
  const [isSeckill, setIsSeckill] = useState<boolean>(false); // 是否是秒杀状态
  const [clickTabBar, setClickTabBar] = useState<number>(0); // 判断点击的是那个tab标识， 通过标识改变背景颜色
  const [cuPage, setCuPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [pageLoading, setLoading] = useState<boolean>(true);
  const [reserveTotal, setReserveTotal] = useState<number>(0);
  const [offsetTop,setOffsetTop] = useState<number>(0)

  useEffect(() => {
    //秒杀页面pv埋点
    sendPv.MActivity_FlashSales()
    initConfig(moduleData);
    getFirstCmsFloorOffsetTop()
  }, []);

  const getFirstCmsFloorOffsetTop = () => {
    // 获取第一个 class 是 cms-floor 的元素
    const firstCmsFloor = document.querySelector('.cms-floor');
    let offsetTop 
    if (firstCmsFloor) {
      // 返回元素距离浏览器顶部的高度
      offsetTop = firstCmsFloor.getBoundingClientRect().top + window.scrollY;
    } else {
      // 如果没有找到元素，返回 null 或抛出错误
      console.warn('未找到 class 为 cms-floor 的元素');
      return null;
    }
    setOffsetTop(offsetTop)
  }

  function checkStartTime(currentTimestamp: number, startTimestamp: string) {
    // 将时间戳转换为 dayjs 对象
    const currentDate = dayjs(currentTimestamp);
    const startDate = dayjs(startTimestamp);

    // 获取当前日期的开始时间（00:00:00）
    const startOfToday = currentDate.startOf('day');

    // 获取明天的开始时间（00:00:00）
    const startOfTomorrow = startOfToday.add(1, 'day');

    // 判断开始时间是今天还是明天
    if (startDate.isSame(startOfToday, 'day')) {
      return 'Upcoming'; // 开始时间是今天
    } else if (startDate.isSame(startOfTomorrow, 'day')) {
      return 'Tomorrow'; // 开始时间是明天
    } else {
      return 'Other'; // 其他情况
    }
  }

  const initConfig = (Data: any) => {
    if (Data && Object.keys(Data).length > 0) {
      // 如果模块数据存在，则使用模块数据
      // pageConfig.ModuleData = getModuleData().externalData.data
      pageConfig.ModuleData = Data?.externalData?.data;
      setReserveTotal(pageConfig?.ModuleData?.reserveTotal || 0)
      if (pageConfig.ModuleData?.seckillBatchDtoList && pageConfig.ModuleData?.seckillBatchDtoList.length) {
        let _list = pageConfig.ModuleData.seckillBatchDtoList;

        _list.forEach((item: seckillBatchDtoT) => {
          // 格式化时间
          const dateObj = new Date(item.startTimeStr);
          const hours = String(dateObj.getHours()).padStart(2, "0");
          const minutes = String(dateObj.getMinutes()).padStart(2, "0");
          item.feTime = `${hours}:${minutes}`;
          // 格式化日期 
          item.feDateTime = checkStartTime(currentMills, item.startTimeStr)
          item.tabName = `${item.startTimeStr}_${item.endTimeStr}`
        });

        let _activeIndex = _list.findIndex((item: seckillBatchDtoT) => item.currentBatch);
        let product = _list[_activeIndex == -1 ? 0 : _activeIndex]?.productList;

        product &&
          product.length > 0 &&
          queryProductFeeds({
            cuPage,
            pageSize,
            batchId: _list[0].batchId,
            lastSkuId: null,
          }).then((res) => {
            if (res && res.data.length > 0) {
              if (res.data.length < pageSize) setLoadingState("noMore");
              let data = res.data;
              _activeIndex >= 0 &&
                data.forEach((product: seckillBatchDtoT) => {
                  product.currentBatch = _list[_activeIndex].currentBatch;
                });

              setProductList(data);
              data && data.length > 0 ? setLoading(false) : setLoading(true);
            }
          });
        const Index = _activeIndex === -1 ? 0 : _activeIndex;
        setIsSeckill(_list[_activeIndex === -1 ? 0 : _activeIndex]?.currentBatch);
        setActiveIndex(Index);
        setTabBarData([..._list]);
        // setProductList(product)
        setLoadError(false);
        setClickTabBar(_list[Index].batchId);
      }else{
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  // 获取当前tab数据
  const getProductListByBatchId = (batchId: number, currentBatch: boolean, cuPage: number) => {
    if (tabBarData && tabBarData.length == 1) return;
    else {
      setLoading(true);
      queryProductFeeds({
        cuPage,
        pageSize,
        batchId: batchId,
        lastSkuId: null,
      }).then((res) => {
        if (res && res.data.length > 0) {
          if (res.data.length < pageSize) setLoadingState("noMore");
          setLoadError(false);
          let _list = res.data;
          _list.forEach((product: seckillBatchDtoT) => {
            product.currentBatch = currentBatch;
          });
          setLoading(true);
          setProductList(_list);
        } else {
          setLoadError(true);
        }
      });
    }
  };

  // 点击头部导航
  const handleSelect = (batchId: number, item: seckillBatchDtoT) => {
    setCuPage(1);
    setClickTabBar(batchId);
    setIsSeckill(item.currentBatch);
    setProductList([])
    setLoadingState("loaded")
    getProductListByBatchId(batchId, item.currentBatch, 1);
  };

  // 点击查看更多
  const ViewMoreClick = async () => {
    //最后一页了 不需要加载更多
    if (loadingState == "noMore") {
      return;
    }
    const lastSkuId = productList.length ? productList[productList.length - 1].skuId : null;
    const page = cuPage + 1;
    setCuPage(page);
    setLoadingState("loading");
    setTimeout(function () {
      queryProductFeedsFn({
        cuPage: cuPage + 1,
        pageSize,
        batchId: clickTabBar,
        lastSkuId: lastSkuId,
      });
    }, 1000);
  };

  // 倒计时结束后
  const onCountdownEndFn = () => {
    window.location.reload();
  };

  // 获取秒杀列表数据
  const queryProductFeedsFn = (props: { cuPage: number; pageSize: number; batchId: number; lastSkuId: string }) => {
    queryProductFeeds({
      ...props,
    }).then((res: any) => {
      if (res && res.data?.length > 0) {
        const product = [...productList, ...(res.data || [])];
        // 如果当前页返回数据小于翻页数量，代表最后一页了
        if (res.total !== 0) {
          if (res.data.length < pageSize) {
            setLoadingState("noMore");
          } else {
            setLoadingState("loaded");
          }
        } else {
          setLoadingState("noMore");
        }

        setProductList(product);
        // 设置  currentBatch 状态
        product.forEach((product) => {
          product.currentBatch = productList[activeIndex].currentBatch;
        });
        // this.$nextTick(() => lazyImage());
      } else {
        setLoadingState("noMore");
      }
    });
  };
  const curTabInfo = () => {
    console.log('tabBarData',tabBarData);
    let tabPos,tabName
    tabBarData.map((item:any,index:number) => {
      if(item.batchId == clickTabBar){
        tabPos = index;
        tabName = item.tabName
      }
    })
    return {
      tabPos,
      tabName
    };
  };

  return (
    <div className={style["uk-pc-seckill"]}>
      <ReservationBtns offsetTop={offsetTop} reserveTotal={reserveTotal}></ReservationBtns>
      {tabBarData && tabBarData.length > 0 ? (   
        <TabBar batchList={tabBarData} currentMills={currentMills} clickTabBar={clickTabBar} onSelect={handleSelect} onCountdownEnd={onCountdownEndFn}></TabBar>
      ) : (
        ""
      )}
      {productList && productList.length > 0 ? (
        <div className={style.content}>
          {/* 主要内容 */}
          <ProductList productList={productList} isSeckillFlag={isSeckill} curTabInfo={curTabInfo()}></ProductList>
          {/* 加载更多 */}
          <ChevronDown ViewMoreClick={ViewMoreClick} loadingState={loadingState}></ChevronDown>
        </div>
      ) : (
        <div className={style.errorBox}>
          {pageLoading ? (
            <div className={style.loading}>
              <img className={style.loadingIcon} src="https://img13.360buyimg.com/img/jfs/t1/6467/28/39418/725/671b6244Faa4fd6d8/1e423ef39e48a9e7.png" alt="" />
              loading...
            </div>
          ) : (
            <div className={style.emptyTxt}>
              <UKPageNothing layout="row" text="There's nothing more" />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
