"use client";
import React from "react";
import styles from "./tips.module.scss";

export default function SaleTips(labels: any) {
  return labels ? (
    <div className={styles.tagGroup}>
      <span className={styles.tips2}>{labels}</span>
      <span className={styles.placeHolder}></span>
    </div>
  ) : (
    <div className={styles.tagGroup}>
      <span className={styles.placeHolder}></span>
    </div>
  );
}
