import { useEffect, useState } from 'react';
import style from './index.module.scss'
import { UKDialog } from "@/common-components-src/js/server"
import { DialogTitle, DialogContent } from "@mui/material"
import { GetReserves } from '@/apis/reservation/reservation.api'
import JDIPrice from "@/common-components-src/js/price/index";
import UKPageNothing from "@/common-components-src/components/UKPageNothing";
import Countdown from '@/components/reservationDialog/countdown/countdown'
import CDNImage from '../cdnImage/cdnImage';
type Props = {
  open: boolean;
  closeFn: any;
  reserveTotal: number;
};
const DialogStyle = {
  "& .MuiDialog-paper": {
    width: "520px",
    margin: "0",
    borderRadius: "12px",
  },
  '& .MuiDialogTitle-root': {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "20px",
    padding: "20px 24px",
  },
  '& .MuiDialogContent-root':{
    maxHeight:'560px',
    paddingTop:'0px'
  }
}
const ReservationDialog = ({ open, closeFn, reserveTotal = 0 }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [reservationList, setReservationList] = useState<any>([])
  useEffect(() => {
    setIsOpen(open);
    if (open) {
      getReserves()
    }
  }, [open])

  const closeHandler = () => {
    setIsOpen(false);
    closeFn && closeFn();
  }
  const getReserves = async () => {
    const res = await GetReserves(5)
    setReservationList(res || [])
  }

  const onCountdownEnd = () => {
    console.log('sxt-onCountdownEnd');
    getReserves()
  }
  //关闭模态框
  return (
    <UKDialog open={isOpen} onClose={closeHandler} showCloseBtn={true} sx={DialogStyle}>
      <DialogTitle>My reservation{reserveTotal ? `${reserveTotal > 1 ? 's' : ''}(${reserveTotal})` : ''}</DialogTitle>
      <DialogContent>
        <div className={style.reservationModel}>
          {
            reservationList.length > 0 ?
              <div className={style.reservatonProducts}>
                {
                  reservationList.map((group: any) => {
                    return (
                      <div className={style.group} key={group.groupId}>
                        <div className={style.countdown}>
                          <div className={style.pinkDot}></div>
                          {
                            group.isCountDown ?
                              <Countdown remainBeginTimes={group.remainBeginTimes * 1000} onCountdownEnd={onCountdownEnd} /> :
                              <span className={style.batchName}>{group.batchName}</span>
                          }

                        </div>
                        {
                          group?.skus && group.skus.length > 0 && group.skus.map((item: any) => {
                            return (
                              <div className={style.product} key={item.skuId}>
                                <div className={style.left}>
                                  <CDNImage src={item.skuImg} width={120} height={120} alt={item.skuName}></CDNImage>
                                </div>
                                <div className={style.right}>
                                  <div className={style.skuName}>{item.skuName}</div>
                                  <JDIPrice
                                    symbol={"£"}
                                    price={item.realPrice}
                                    containerStyle={{ lineHeight: '18px' }}
                                    integerStyle={{ fontSize: '18px' }}
                                    decimalPointStyle={{ fontSize: '16px' }}
                                    decimalPlaceStyle={{ fontSize: '16px' }}
                                    uiType={1}
                                  /> 
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  })
                }
              </div> :
              <div className={style.nothing}>
                <UKPageNothing text={"The list is empty—make your reservation now!"} />
              </div>
          }
        </div>
      </DialogContent>

    </UKDialog>
  )
}

export default ReservationDialog;
