"use client";

import "./style.scss";
import style from "./style.module.scss";
import { callAfterLogin } from "@/lib/jdiClientUtils";
import AlmostSoldout from "./img/AlmostSoldout.svg";
import ArrowRight from "@/assets/icons/ArrowRightBlack.svg";
import JDIProductCardV2 from "@/common-components-src/js/productCard_V2/index";
import JumpLink from "@/components/JumpLink";
import { sendClickTrack, EXPOSURE_RECORD } from "@/tracks/25223";
interface newComerPropTypes {
  moduleData: any;
  currencySymbol: string;
}
export default function Module({ moduleData, currencySymbol }: newComerPropTypes) {
  const pageConfig: any = {};
  const { dataBatch } = moduleData;

  const handlerGotoLand = () => {
    callAfterLogin(() => {
      window.location.href = pageConfig.jumpParams.jumpValue.link;
    });
  };

  const initConfig = (moduleData: any) => {
    pageConfig.total = moduleData.externalData?.data?.products?.total;
    pageConfig.products = moduleData.externalData?.data?.products.list.slice(0, 5);
    pageConfig.jumpParams = moduleData.dataBatch?.config[0]?.moreLinks;
    pageConfig.containerStyle = {
      marginTop: (moduleData.dataStyle.style.mt || 0) + "px",
      marginBottom: (moduleData.dataStyle.style.mb || 0) + "px",
    };
    pageConfig.currency = currencySymbol;
    pageConfig.products?.forEach((p: any, index: number) => {
      // -----------商卡信息------------
      p.type = "1";
      p.skuImg = p?.imageUrl;
      p.skuId = p?.skuId;
      p.skuName = p?.skuName;
      p.currency = pageConfig.currency;
      p.realPrice = p?.realPrice;
      p.originalPrice = p?.originalPrice;
      p.shouldShowCart = true;
      p.imgTips = p?.extInfo?.salesVolumeDesc ? { position: "topLeft", tipDom: <AlmostSoldout style={{ marginLeft: "10px" }} /> } : {};
      p.onClickProduct = (props: any) => handlerGotoLand();

      // -----------商卡样式------------
      p.realPriceJDIPriceParams = {
        integerStyle: {
          fontSize: "24px",
          color: "#cc0c1c",
        },
        decimalPointStyle: {
          color: "#cc0c1c",
        },
        decimalPlaceStyle: {
          color: "#cc0c1c",
        },
        symbolStyle: {
          color: "#cc0c1c",
        },
      };
      p.skuNameStyle = {
        color: "#FF0F23",
      };

      p.skuImgStyle = {
        width: "200px",
        height: "200px",
      };
      p.width = "220px";
      p.height = "272px";
      p.trackingPointParams = genEventTrackingInfo(p, index);
    });
  };

  // 生成埋点信息
  const genEventTrackingInfo = (product: any, index: number) => {
    const eventTrackingInfo = {} as { [key: string]: any };
    const json_param = { skuid: product.skuId.toString(), index: index };
    const expConfig = {
      useClick: false,
      stay_duration: 0,
      repeated: false,
      area_rate: 0.01,
    };
    eventTrackingInfo["productClickPoint"] = () => sendClickTrack.Home_Welcome(json_param);
    eventTrackingInfo["productParam"] = json_param;
    eventTrackingInfo["productExpConfig"] = {
      event_id: EXPOSURE_RECORD.Home_Welcome_Expo,
      config: expConfig,
      json_param: json_param,
    };
    return eventTrackingInfo;
  };

  initConfig(moduleData);

  return (
    <>
      {!!pageConfig?.products?.length && (
        <div className={style.discount_list_wrap} style={pageConfig.containerStyle}>
          <div className={style.title}>Welcome Discounts</div>
          {/**只有新人模块需要先登录再跳转，但是JumpLink会先处理内部逻辑，即判断完链接类型立马跳转。
           * 所以不从JumpLink组件跳转而是处理点击事件判断登录状态之后再跳转
           */}
          {/* <JumpLink jumpParams={pageConfig.jumpParams}> */}
          <div
            onClick={() => {
              sendClickTrack.Home_Welcome_More();
              handlerGotoLand();
            }}
            exptag={"exp|" + EXPOSURE_RECORD.Home_Welcome_More_Expo}
            data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
          >
            <div className={style.land_btn}>
              <p className={style.land_text}>New users exclusive</p>
              <ArrowRight color="#1A1A1A" className={style.arrow_right} />
            </div>
          </div>
          {/* </JumpLink> */}
          <div className={style.card_wrap}>
            <div className={style.card_slider}>
              {pageConfig?.products.map((product: any, index: number) => (
                <div key={index}>
                  <JDIProductCardV2 key={product.skuId} productData={product} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
