'use client'
import UKPageNothing from "@/common-components-src/components/UKPageNothing";
import style from './style.module.scss'

//服务端加载接口数据
export function Nothing({ text }: any) {
  return (
    <div className={style.nothing}>
      <UKPageNothing text={text}/>
    </div>
  )
}
