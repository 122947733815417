"use client";
import { useEffect, useState } from "react";
import { UKDialog } from "@/common-components-src/js/server"
import UKPageNothing from "@/common-components-src/components/UKPageNothing";
import { DialogTitle, DialogContent } from "@mui/material"
import { fetchQuestionDetail } from "@/apis/help/help.api";
import RichTextLoading from '@/app/help/[id]/components/FAQ/RichTextLoading'
import dynamic from "next/dynamic";
import style from './index.module.scss'
type Props = {
  open: boolean;
  closeFn: any;
  helpId: string
};
const DialogStyle = {
  "& .MuiDialog-paper": {
    width: "520px",
    margin: "0",
    borderRadius: "12px",
  },
  '& .MuiDialogTitle-root': {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "20px",
    padding: "20px 24px 0px 24px",
  },
}

const ReadOnlyRichText = dynamic(() => import("@/app/help/[id]/components/FAQ/ReadOnlyRichTexts"), { ssr: false, loading: () => <RichTextLoading /> });
const RulesDialog = ({ open, closeFn, helpId }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState<string>('');
  const [detail, setDetail] = useState<string>('');

  const closeHandler = () => {
    setIsOpen(false);
    closeFn && closeFn();
  }

  // 获取问题详情
  const getDetail = async () => {
    const { data, code } = await fetchQuestionDetail({ id: Number(helpId) });
    if (code === "200") {
      setDetail(data?.facadeContent || '');
      setTitle(data?.facadeName || '');
    } else {
      setDetail('')
      setTitle('')
    }
  };

  useEffect(() => {
    setIsOpen(open);
    if (open) {
      getDetail()
    }
  }, [open])

  return (
    <UKDialog
      open={isOpen}
      onClose={closeHandler}
      showCloseBtn={true}
      sx={DialogStyle}
    >
      {
        <>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            {
              detail ?
                <ReadOnlyRichText detail={detail} /> :
                <div className={style.nothing}>
                  <UKPageNothing />
                </div>
            }
          </DialogContent>
        </>
      }
    </UKDialog>
  )
}

export default RulesDialog;
