"use client";
import Recommend from "@/common-components-src/js/recommend/index";
import { EXPOSURE_RECORD, sendClickTrack } from "@/tracks/25324";
export default function Module() {
  return (
    <div style={{ width: "1264px", marginLeft: "auto", marginRight: "auto" }}>
      <Recommend
        sceneCode="home_page"
        trackParams={{
          RecomSku_Expo: EXPOSURE_RECORD.Home_RecomSku_Expo,
          RecomSku_Tab_Expo: EXPOSURE_RECORD.Home_RecomSku_Tab_Expo,
          RecomSku_Addcart_Expo: EXPOSURE_RECORD.Home_RecomSku_Addcart_Expo,
          productClick: sendClickTrack.Home_RecomSku,
          tabClick: sendClickTrack.Home_RecomSku_Tab,
          addCartClick: sendClickTrack.Home_RecomSku_Addcart,
        }}
      />
    </div>
  );
}
